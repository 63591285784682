type CirclePlusProps = { size: number };

const CirclePlus = ({ size }: CirclePlusProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={size}
      height={size}
      className="mx-2 fill-gray-800 dark:fill-neutral-200 transform -translate-x-2 -translate-y-2 hover:cursor-pointer"
      viewBox="0 0 24 24"
    >
      <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z" />
    </svg>
  );
};

export default CirclePlus;
