const Cross = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="my-auto"
      style={{
        width: '1em',
        height: '1em',
        verticalAlign: 'middle',
        overflow: 'hidden',
      }}
      viewBox="0 0 1024 1024"
    >
      <path d="M647.744 285.696 512 421.504 376.256 285.696l-90.56 90.56L421.504 512 285.76 647.744l90.496 90.496L512 602.496 647.744 738.24l90.496-90.496L602.496 512 738.24 376.256l-90.496-90.56zm226.304-135.744c-199.936-199.936-524.16-199.936-724.096 0-199.936 199.936-199.936 524.16 0 724.096 199.936 199.936 524.16 199.936 724.096 0 199.936-199.936 199.936-524.16 0-724.096zm-90.496 633.6c-149.952 149.952-393.088 149.952-543.04 0s-149.952-393.088 0-543.04c149.952-149.952 393.088-149.952 543.04 0 149.952 149.952 149.952 393.024 0 543.04z" />
    </svg>
  );
};

export default Cross;
